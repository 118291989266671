export default {
  meta: {
     code: 'es',
     name: 'Español',
  },

  auth: {
     
  },


  main: {
     cancel: 'cancel',
     Cancel: 'Cancel',
     delete: 'delete',
     Delete: 'Delete',
     edit: 'edit',
     Edit: 'Edit',
     EditDelete: 'edit, delete',
     save: 'save',
     validate: 'validate',
     Validate: 'Validate',
     password: 'Password',
     login: 'Login',
     register: 'Create your account',
     back: 'back',
     knowMore: 'know more',
     user: 'user',
     users: 'users',
     server: 'server',
     Server: 'Server',
     Confidentiality: 'Confidentiality',
     mentionLegal: 'Legales',
     follower: 'follower', 
     followers: 'followers',
     About: 'About',
     Finished: 'Ok',
     Contacts: 'Contacts',
     Messages: 'Messages',
     New: 'New',
     Image: 'Image',
     MyProfil: 'My profil',
     EventType: 'Tipo de evento',
     City: 'Ciudad',
     Mood: 'Atmósfera'
  },

  menu: {
     
  },


  home: {
   letsGo: "Vamos",
   OurChallengeCourseFormulas: "Nuestras fórmulas de cursos de desafío en París"
  },

  register: {
     didNotSignedManifest: 'You have not yet signed the manifest',
     readSignedManifest: 'Read and sign the manifest',
     manifestSigned: 'Manifest signed',
     createYourAccount: 'Create your account',

     desc12: 'No need to give any e-mail adresse',
     desc13: 'just a pseudo and a password',
     desc14: 'We don\'t want to know who you are',
     desc15: 'We only want to know what we can do together',

     alreadyHaveAccount: "Already have an account"
  },

  login: {
     connectToAccount: 'Connect',
     name: 'Pseudo, username',
     errorData: 'Sorry, incorrect connexion data',
     errorUserNotExists: 'Sorry, this user account does not exists in this server',
     errorUserPageNotExists: 'Sorry, the page associated to your user account does not exists in this server',
     errorWrongPwd: 'Sorry, this account exists, but it is a wrong password',
     errorServerUnreachable: 'Sorry, the server is unreachable',

  },

  form: {
     required: 'Required',
     characters: 'characters',
     noSpace: 'No space',
     atLeast8Char: 'At least 8 characters',
     atLeast3Char: 'At least 3 characters',
  },

  error: {
     CredentialsAreInvalid : 'Credentials are invalid',
     errorData: 'Sorry, incorrect connexion data',
     errorUserNotExists: 'Sorry, this user account does not exists in this server',
     errorUserPageNotExists: 'Sorry, the page associated to your user account does not exists in this server',
     errorWrongPwd: 'Sorry, this account exists, but it is a wrong password',
     errorServerUnreachable: 'Sorry, the server is unreachable',
     
  },

}
